import * as React from 'react';
import styled from 'styled-components';
import {
  IoAdd,
  IoAlertCircleSharp,
  IoArrowBack,
  IoArrowForward,
  IoBan,
  IoCheckmarkSharp,
  IoClose,
  IoCloudOffline,
  IoDownload,
  IoHelp,
  IoHourglass,
  IoPencilSharp,
  IoShieldCheckmark,
  IoTime
} from 'react-icons/io5';

import { Button, ButtonContainer, ButtonLink } from '@oysterjs/ui/Button';
import { PageSection, TwoPaneContainer } from '@oysterjs/ui/Page';
import {
  getPolicyCoverageItemsLongDescription,
  getPolicyDisplayName,
  getPolicyOptionalCoverageItem,
  getProductDisplayImage,
  getProductDisplayName
} from '@oysterjs/core/policy';
import {
  BikeProduct,
  BooleanOption,
  Policy,
  Product,
  ProductType,
  PolicyState,
  PolicyType,
  ElectronicsProduct,
  ElectronicsType,
  Price,
  BikePowerSource,
  ActivationTrigger,
  ActivationSource,
  AccountSummary
} from '@oysterjs/types';
import { CollectBikeInfo } from '@oysterjs/getoyster/pages/bike/collect';
import {
  addEndorsement,
  cancelEndorsement,
  getEndorsementPremium
} from '@oysterjs/core/api/policy';
import { CollectJewelryInfo } from '@oysterjs/getoyster/pages/jewelry/collect';
import { CollectElectronicsInfo } from '@oysterjs/getoyster/pages/electronics/collect';
import { ProductInfoPage } from '@oysterjs/getoyster/pages/product';
import { JewelryProduct, MotorProduct } from '@oysterjs/types';
import {
  matchPath,
  Redirect,
  Route as BrowserRoute,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { NavLink } from 'react-router-dom';
import { CoverageItem } from '@oysterjs/ui/CoverageItem';
import { getPolicyDocumentUrl } from '@oysterjs/core/statics';
import { Badge } from '@oysterjs/ui/Badge';
import {
  AlternateBoxTitle,
  Box,
  BoxBadgeHeader,
  BoxContainer,
  BoxItem,
  BoxItemImage
} from '@oysterjs/ui/Box';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import { getPages, getManualActivationPages } from '../ActivateDashboard';
import { Progress } from '@oysterjs/ui/Form/progress';
import { Switch as FormSwitch } from '@oysterjs/ui/Form/switch';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { PolicyLineItem, PolicyStatement } from './account';
import { updatePolicyActivation } from '@oysterjs/core/api/activateDashboard';
import { WrappedError } from '@oysterjs/core/errors';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { TextInput } from '@oysterjs/ui/Form/text';
import { BikeSerialNumberBanner } from './policies';
import { Banner } from '@oysterjs/ui/Banner';

export const getPolicyStatus = (
  policy: Policy,
  account: AccountSummary
): {
  noDocument?: boolean;
  backgroundColor: string;
  badgeColor: string;
  badge: JSX.Element;
  description: JSX.Element;
  premiumText: JSX.Element;
  stateText: string;
  buttonText?: string;
} => {
  switch (policy.State) {
    case PolicyState.unknown:
      return {
        noDocument: true,
        backgroundColor: '#e6e6e6',
        badgeColor: 'rgba(0, 0, 0, 0.5)',
        badge: <IoPencilSharp />,
        description: (
          <>
            Your application was <b>started</b> but not completed.
          </>
        ),
        premiumText: <>Your premium will be determined after you submit your application.</>,
        stateText: 'In Progress',
        buttonText: 'Continue Application'
      };

    case PolicyState.offerSubmitted:
      if (
        policy.Coverage.ActivationTrigger &&
        policy.Coverage.ActivationTrigger.Source == ActivationSource.ManuallyActivate &&
        policy.InsuredItems.some((i) => i.HasNotReceived) &&
        !!account?.PaymentMethods?.length
      ) {
        return {
          noDocument: true,
          backgroundColor: 'rgba(252, 227, 193)',
          badgeColor: 'rgba(214, 131, 0)',
          badge: <IoAlertCircleSharp style={{ color: 'rgba(214, 131, 0)' }} />,
          description: (
            <>
              Your application has been <b>submitted</b>, but it has not been <b>activated</b>.
            </>
          ),
          premiumText: <>Your payment will be processed when your policy activates.</>,
          stateText: 'Pending Activation',
          buttonText: 'Activate Policy'
        };
      }
      return {
        noDocument: true,
        backgroundColor: 'rgba(252, 227, 193)',
        badgeColor: 'rgba(214, 131, 0)',
        badge: <IoAlertCircleSharp style={{ color: 'rgba(214, 131, 0)' }} />,
        description: (
          <>
            We need <b>more information</b> to process your policy.
          </>
        ),
        premiumText: <>Your payment will be processed when your policy activates.</>,
        stateText: 'Pending Submission',
        buttonText: 'Finish Application'
      };

    case PolicyState.applicationSubmitted:
    case PolicyState.policyBinding:
      if (policy.Coverage.ActivationTrigger && policy.InsuredItems.some((i) => i.HasNotReceived)) {
        return {
          noDocument: true,
          backgroundColor: 'rgba(252, 227, 193)',
          badgeColor: 'rgba(214, 131, 0)',
          badge: <IoAlertCircleSharp style={{ color: 'rgba(214, 131, 0)' }} />,
          description: (
            <>
              Your application has been <b>submitted</b>, but it has not been <b>activated</b>.
            </>
          ),
          premiumText: <>Your payment will be processed when your policy activates.</>,
          stateText: 'Pending Activation',
          buttonText: 'Activate Policy'
        };
      }
      return {
        noDocument: true,
        backgroundColor: 'rgba(178,243,252,0.4)',
        badgeColor: 'rgb(16,98,110,0.4)',
        badge: <IoHourglass style={{ color: 'rgb(16,98,110,0.4)' }} />,
        description: (
          <>
            Your policy is being <b>processed</b>. Our team is reviewing your information.
          </>
        ),
        premiumText: <>Your payment will be processed when your policy activates.</>,
        stateText: 'Processing',
        buttonText: 'View Policy'
      };

    case PolicyState.policyInforce:
      return {
        backgroundColor: 'rgba(189, 234, 205)',
        badgeColor: 'rgba(57, 145, 88)',
        badge: <IoShieldCheckmark style={{ color: 'rgba(57, 145, 88)' }} />,
        description: (
          <>
            Your policy is <b>active</b> and your items are protected.
          </>
        ),
        premiumText: (
          <>
            Your policy is effective until <b>{new Date(policy.ExpiresAt).toLocaleDateString()}</b>.
          </>
        ),
        stateText: 'Active',
        buttonText: 'View Policy'
      };

    case PolicyState.policyCanceled:
      return {
        backgroundColor: 'rgb(255, 193, 185)',
        badgeColor: 'rgb(201, 67, 48)',
        badge: <IoBan style={{ color: 'rgb(201, 67, 48)' }} />,
        description: (
          <>
            Your policy has been <b>canceled</b>. Contact support for more information.
          </>
        ),
        premiumText: <>You won't be charged anymore.</>,
        stateText: 'Canceled',
        buttonText: 'View Policy'
      };

    case PolicyState.policyExpired:
      return {
        backgroundColor: '#e6e6e6',
        badgeColor: 'rgba(0, 0, 0, 0.5)',
        badge: <IoTime style={{ color: 'rgba(0, 0, 0, 0.5)' }} />,
        description: (
          <>
            Your policy has <b>expired</b>. Contact support for more information.
          </>
        ),
        premiumText: <>You won't be charged anymore.</>,
        stateText: 'Expired',
        buttonText: 'View Policy'
      };

    default:
      return {
        backgroundColor: '#e6e6e6',
        badgeColor: 'rgba(0, 0, 0, 0.5)',
        badge: <IoHelp />,
        description: <>There's an issue with your policy. Contact support for more information.</>,
        premiumText: <>You won't be charged anymore.</>,
        stateText: 'Unknown',
        buttonText: 'View Policy'
      };
  }
};

const getProductDetails = (product: Product) => {
  switch (product.Type) {
    case ProductType.motorcycle:
    case ProductType.offroad: {
      const motorProduct = product.Details as MotorProduct;
      return [
        {
          title: 'Total Value',
          detail: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.Price.Currency,
            minimumFractionDigits: 0
          }).format(product.Price.Amount)
        },
        { title: 'VIN', detail: motorProduct.VIN },
        { title: 'Type', detail: motorProduct.Type },
        { title: 'Make', detail: motorProduct.Make },
        { title: 'Model', detail: motorProduct.Model },
        { title: 'CC', detail: motorProduct.CCSize }
      ];
    }

    case ProductType.bike: {
      const bikeProduct = product.Details as BikeProduct;
      const renderClass = (): string | undefined => {
        return {
          [BikePowerSource.pedalAssist20MPH]: 'Class I',
          [BikePowerSource.throttleAssist20MPH]: 'Class II',
          [BikePowerSource.pedalAssist28MPH]: 'Class III'
        }[bikeProduct.PowerSource || ''];
      };
      return [
        {
          title: 'Insured Value',
          detail: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.Price.Currency,
            minimumFractionDigits: 0
          }).format(Math.max(bikeProduct.TotalInsuredValue?.Amount || 0, product.Price.Amount || 0))
        },
        { title: 'Serial Number', detail: bikeProduct.FrameSerialNumber || 'N/A' },
        { title: 'Model Year', detail: bikeProduct.ModelYear },
        { title: 'E-bike Class', detail: renderClass() }
      ].filter((v) => !!v.detail);
    }

    case ProductType.jewelry: {
      return [
        {
          title: 'Value',
          detail: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.Price.Currency,
            minimumFractionDigits: 0
          }).format(product.Price.Amount)
        }
      ].filter((v) => !!v.detail);
    }

    case ProductType.phone: {
      const phoneProduct = product.Details as ElectronicsProduct;

      return [
        { title: 'Identification', detail: phoneProduct.SerialNumber || product.SKU },
        {
          title: 'Value',
          detail: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.Price.Currency,
            minimumFractionDigits: 0
          }).format(product.Price.Amount)
        },
        { title: 'Phone Type', detail: phoneProduct.Type }
      ];
    }

    // TODO: refactor to use the ProductType.phone case for phone product (ticket: https://app.asana.com/0/1200762684494204/1203949127097148/f)
    case ProductType.electronics: {
      const electronicsProduct = product.Details as ElectronicsProduct;
      const typeTitle = [ElectronicsType.iPhone, ElectronicsType.smartPhone].includes(
        electronicsProduct.Type as ElectronicsType
      )
        ? 'Phone Type'
        : 'Electronics Type';

      return [
        { title: 'Identification', detail: electronicsProduct.SerialNumber || product.SKU },
        {
          title: 'Value',
          detail: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.Price.Currency,
            minimumFractionDigits: 0
          }).format(product.Price.Amount)
        },
        { title: typeTitle, detail: electronicsProduct.Type }
      ];
    }
  }
};

export const PolicyStatusBadge = styled.div`
  border-radius: 50%;
  padding: 24px;
  background: rgba(230, 230, 230, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PolicyDocument = styled.a`
  display: block;
  border: 1px solid rgba(33, 160, 253, 0.2);
  background: rgba(33, 160, 253, 0.2);
  color: rgba(0, 110, 191, 0.75);
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(33, 160, 253, 0.33);
    background: rgba(33, 160, 253, 0.33);
    color: rgba(0, 110, 191, 0.83);
  }

  &:active {
    border: 1px solid rgba(33, 160, 253, 0.5);
    background: rgba(33, 160, 253, 0.5);
    color: rgba(0, 110, 191, 1);
  }

  &:visited {
    color: rgba(0, 110, 191, 0.75);
  }
`;

const PolicyDocumentNotAvailable = styled.div`
  border: 1px dashed #d2d2d2;
  background: #eaeaea;
  color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
`;

const PolicyPremium = (props: { price?: Price }) => (
  <div
    style={{
      height: '74px',
      lineHeight: '74px',
      fontWeight: 500,
      fontSize: '2.2em'
    }}
  >
    {new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: props.price?.Currency || 'usd',
      minimumFractionDigits: Number.isInteger(props.price?.Amount) ? 0 : 2,
      maximumFractionDigits: 2
    }).format(props.price?.Amount || 0)}
  </div>
);

const PolicyActivation = (props: { policy: Policy; onActivate: (policy: Policy) => void }) => {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const [activation, setActivation] = React.useState(false);
  const goBack = () => history.replace(url.split('/').slice(0, -1).join('/') + '/overview');

  const pages = getPages(path, props.policy.ID, props.policy.Type, props.onActivate);
  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  React.useEffect(() => {
    setActivation(window.location.pathname.indexOf('/activate') >= 0);
  }, [window.location.pathname]);

  return (
    <SlideOut showing={activation} onClose={goBack} onBack={goBack} title="Activate Policy">
      <ErrorBoundary forceMobile>
        {current >= 0 && pages.length > 2 && (
          <PageSection noBorder noPadding>
            <Progress steps={pages.map((page) => page.icon)} currentStep={current} />
          </PageSection>
        )}
        <Switch>
          {pages.map((page) => (
            <Route exact key={page.path} path={page.path} render={page.render} />
          ))}
        </Switch>
      </ErrorBoundary>
    </SlideOut>
  );
};

const getPolicyDescription = (activationTrigger: ActivationTrigger) => {
  switch (activationTrigger.Source) {
    case ActivationSource.ManuallyActivate:
      return 'Your policy is pending activation. Please manually activate your policy.';
    case ActivationSource.SetStartDate:
      if (!activationTrigger.StartDate) {
        return 'Your policy is pending activation. Please manually activate your policy.';
      } else {
        const startDate = new Date(activationTrigger.StartDate);
        return `Your policy is set to activate automatically on ${Intl.DateTimeFormat('en-US', {
          dateStyle: 'full'
        }).format(startDate)}. You can also manually activate it anytime.`;
      }
    case ActivationSource.TrackingNumber:
      return `Your policy is set to activate automatically when your package is delivered. The tracking number is ${activationTrigger.TrackingNumber}. You can also manually activate it anytime.`;
  }
};

const StartPolicy = (props: { policy: Policy; onActivate: (policy: Policy) => void }) => {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const [activation, setActivation] = React.useState(false);
  const goBack = () => history.replace(url.split('/').slice(0, -1).join('/') + '/overview');

  const pages = getManualActivationPages(path, props.policy.ID, props.onActivate);

  React.useEffect(() => {
    setActivation(window.location.pathname.indexOf('/activate') >= 0);
  }, [window.location.pathname]);

  return (
    <SlideOut showing={activation} onClose={goBack} onBack={goBack} title="Activate Policy">
      <ErrorBoundary forceMobile>
        <Switch>
          {pages.map((page) => (
            <Route exact key={page.path} path={page.path} render={page.render} />
          ))}
        </Switch>
      </ErrorBoundary>
    </SlideOut>
  );
};

const PolicyOverview = (props: { policy: Policy; account: AccountSummary }): JSX.Element => {
  const [policy, setPolicy] = React.useState(props.policy);
  const [additionalText, setAdditionalText] = React.useState(<></>);
  const status = getPolicyStatus(policy, props.account);

  React.useEffect(() => {
    setPolicy(policy);
  }, [props.policy]);

  React.useEffect(() => {
    if (props.policy.InsuredItems.some((insuredItem) => insuredItem.Pending)) {
      getEndorsementPremium(props.policy.ID, props.policy.InsuredItems[0]).then(
        ({ PendingPrice }) => {
          const pendingText =
            PendingPrice.Amount != policy.Price?.Amount ? (
              <>
                Your premium may change to{' '}
                <b>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: PendingPrice?.Currency || 'usd',
                    minimumFractionDigits: Number.isInteger(PendingPrice?.Amount) ? 0 : 2,
                    maximumFractionDigits: 2
                  }).format(PendingPrice.Amount)}
                </b>{' '}
                if all endorsements are approved.
              </>
            ) : (
              <></>
            );
          setAdditionalText(pendingText);
        }
      );
    }
  }, [props.policy]);

  return (
    <>
      {policy.State === PolicyState.offerSubmitted &&
      (!policy.Coverage.ActivationTrigger ||
        policy.Coverage.ActivationTrigger.Source != ActivationSource.ManuallyActivate ||
        props.account.PaymentMethods == null) ? (
        <PolicyActivation policy={policy} onActivate={setPolicy} />
      ) : (
        policy.Coverage.ActivationTrigger &&
        policy.InsuredItems.some((i) => i.HasNotReceived) && (
          <>
            <Banner
              title={'Your coverage is not active yet'}
              description={getPolicyDescription(policy.Coverage.ActivationTrigger)}
              bannerAction={
                <ButtonLink
                  href={`/policies/${policy.ID}/overview/activate/manual`}
                  primary
                  icon={<IoArrowForward />}
                >
                  Activate Policy
                </ButtonLink>
              }
            ></Banner>
            <StartPolicy policy={policy} onActivate={setPolicy} />
          </>
        )
      )}
      <BoxContainer>
        <BoxItem title="Policy Status" description={status.description}>
          <PolicyStatusBadge
            style={{
              fontSize: '1.6em',
              background: status.backgroundColor,
              color: status.badgeColor
            }}
          >
            {status.badge}
          </PolicyStatusBadge>
        </BoxItem>
        <BoxItem
          title="Annual Premium"
          description={
            <>
              {status.premiumText}
              {additionalText}
            </>
          }
        >
          <PolicyPremium price={props.policy.Price} />
        </BoxItem>
        <BoxItem
          title="Policy Document"
          description={
            status.noDocument
              ? "We're still working on your application. Hang tight!"
              : 'View your full policy document.'
          }
        >
          {status.noDocument ? (
            <PolicyDocumentNotAvailable>
              <IoCloudOffline />{' '}
            </PolicyDocumentNotAvailable>
          ) : (
            <PolicyDocument target="_blank" href={getPolicyDocumentUrl(policy.ID)}>
              <IoDownload />
            </PolicyDocument>
          )}
        </BoxItem>
      </BoxContainer>
    </>
  );
};

const SerialNumberInput = (props: {
  policy: Policy;
  onPolicyUpdated: (updatedPolicy: Policy) => void;
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [itemID, setItemID] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const goBack = () => history.replace(url.split('/').slice(0, -3).join('/') + `/items`);

  React.useEffect(() => {
    const matches = window.location.pathname.match('.+\\/items\\/(.+)\\/serialnumber') || [];
    // For valid URL structure, there should be 2 matches
    if (matches.length >= 2) {
      // Get the first group match (index 0 is whole match)
      const matchedItemID = matches[1];
      setItemID(matchedItemID);

      // Set initial serial number field
      const item = props.policy.InsuredItems.find((p) => p.ID === matchedItemID);
      if (!item) {
        setItemID('');
        throw new Error(`Item cannot be found in policy.`);
      } else {
        setSerialNumber((item.Details as BikeProduct).FrameSerialNumber);
      }
    }
  }, [window.location.pathname]);

  const product = () => {
    return props.policy.InsuredItems.find((p) => p.ID === itemID);
  };

  const onSubmit = async () => {
    const items = props.policy.InsuredItems;
    const indexToUpdate = items.findIndex((p) => p.ID === itemID);
    if (indexToUpdate > -1) {
      (items[indexToUpdate].Details as BikeProduct).FrameSerialNumber = serialNumber;
    }

    const updatedPolicy = {
      ...props.policy,
      InsuredItems: items
    };

    setLoading(true);
    setError(undefined);
    try {
      // Update the policy and set the policy based on the backend response,
      // which may have rejected the update.
      const res = await updatePolicyActivation(props.policy.ID, updatedPolicy);
      // Update policy state if updated from backend
      if (res.Policy) {
        props.onPolicyUpdated(res.Policy);
      }
      goBack();
    } catch (e) {
      // If there was an error, revert to the original policy
      const err = WrappedError.asWrappedError(e);
      props.onPolicyUpdated(props.policy);
      setError(err);
    } finally {
      // In any case, set the loading flag to false
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <FormRowHeader
        title={`Enter the serial number for ${product()?.Name || 'bike'}`}
        description={`You can find the serial number on the frame of your bike. If you're having any issues, please contact your bike manufacturer.`}
      />
      <FormRow breakMobile>
        <FormColumn>
          <TextInput
            disabled={loading}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setSerialNumber(value);
            }}
            error={
              error
                ? `We encountered an issue updating the serial number: ${error.message}`
                : undefined
            }
            value={serialNumber}
          />
        </FormColumn>
      </FormRow>
      <PageSection noBorder>
        <ButtonContainer center>
          <Button
            icon={<IoCheckmarkSharp />}
            primary
            disabled={!serialNumber}
            loading={loading}
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};

const InsuredItemSubTitle = styled.div`
  font-size: 1em;
  color: #666666;
`;

const InsuredItemProperties = styled.ul`
  list-style: none;
  padding: 20px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

const InsuredItemPropertyItem = styled.li`
  flex: 1 0 calc(50% - 20px);
`;

const InsuredItemBadge = (props: { pending?: boolean }) => {
  const backgroundColor = props.pending ? 'rgba(252, 227, 193)' : 'rgba(189, 234, 205)';
  const badge = props.pending ? (
    <IoHourglass style={{ color: 'rgba(214, 131, 0)' }} />
  ) : (
    <IoShieldCheckmark style={{ color: 'rgba(57, 145, 88)' }} />
  );
  const text = props.pending ? 'Pending' : 'Protected';

  return <Badge icon={badge} label={text} color={backgroundColor} />;
};

const InsuredItemProperty = (props: { title: string; detail?: string }) => (
  <InsuredItemPropertyItem>
    <div style={{ fontWeight: 500, color: '#666666', fontSize: '0.8em' }}>{props.title}</div>
    <div style={{ color: '#000000', fontSize: '0.8em' }}>{props.detail}</div>
  </InsuredItemPropertyItem>
);

const InsuredItem = (props: {
  item: Product;
  policyType: PolicyType;
  onCancelEndorsement: () => void;
  onAddSerialNumber: () => void;
}) => {
  const requireSerialNumber = (): boolean => {
    if (props.policyType === PolicyType.bike || props.policyType === PolicyType.markelBike) {
      const bikeProduct = props.item.Details as BikeProduct;
      return !!bikeProduct.IsPendingSerialNumber || !bikeProduct.FrameSerialNumber;
    } else {
      return false;
    }
  };

  return (
    <Box className="insured-item-box-item">
      <BoxBadgeHeader>
        <InsuredItemBadge pending={props.item.Pending} />
        {props.item.Pending && (
          <Button
            style={{ padding: '5px 10px' }}
            leftIcon={<IoClose />}
            onClick={props.onCancelEndorsement}
          >
            Cancel
          </Button>
        )}
      </BoxBadgeHeader>
      {requireSerialNumber() && (
        <Button
          style={{ padding: '5px 10px', marginBottom: '10px', background: 'rgba(252, 227, 193)' }}
          leftIcon={<IoAdd style={{ color: 'rgba(214, 131, 0)' }} />}
          onClick={props.onAddSerialNumber}
        >
          Add serial number
        </Button>
      )}
      <BoxItemImage src={getProductDisplayImage(props.item.Type, props.item.ImageURL)} />
      <AlternateBoxTitle>{props.item.Name}</AlternateBoxTitle>
      <InsuredItemSubTitle>
        {getProductDisplayName(props.item.Type, props.item)}
      </InsuredItemSubTitle>
      <InsuredItemProperties>
        {getProductDetails(props.item).map((detail) => (
          <InsuredItemProperty key={detail.title} {...detail} />
        ))}
      </InsuredItemProperties>
    </Box>
  );
};

const PolicyCoveredItems = (props: {
  policy: Policy;
  onPolicyUpdate: (updatedPolicy: Policy) => void;
}): JSX.Element => {
  const [policy, _setPolicy] = React.useState(props.policy);
  const [currentPrice, setCurrentPrice] = React.useState(props.policy.Price);
  const [pendingPrice, setPendingPrice] = React.useState(props.policy.Price);
  const [endorsementPrice, setEndorsementPrice] = React.useState(props.policy.Price);
  const [showEndorsementFlow, setShowEndorsementFlow] = React.useState(false);
  const [showNewPremium, setShowNewPremium] = React.useState(false);
  const [showSerialNumberEditor, setShowSerialNumberEditor] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState({} as Product);
  const history = useHistory();
  const { url } = useRouteMatch();

  const setPolicy = (updatedPolicy: Policy) => {
    _setPolicy(updatedPolicy);
    props.onPolicyUpdate(policy);
  };

  const onCancelEndorsement = (itemId: string) => {
    cancelEndorsement(policy.ID, itemId).then(({ Policy }) => setPolicy(Policy));
  };

  // Serial number editor logic
  React.useEffect(() => {
    const matches = window.location.pathname.match('.+\\/items\\/(.+)\\/serialnumber') || [];
    const isActive = matches?.length > 0;
    setShowSerialNumberEditor(isActive);
  }, [window.location.pathname]);

  const closeSerialNumberSlideOut = () =>
    history.replace(url.split('/').slice(0, -3).join('/') + `/items`);

  const onAddSerialNumber = (_: Policy, itemId: string) => {
    history.push(url.split('/').slice(0, -1).join('/') + `/items/${itemId}/serialnumber`);
  };

  /* show the right form for a product given the policy type */
  const getPolicyForm = () => {
    switch (policy.Type) {
      case PolicyType.bike:
      case PolicyType.markelBike:
        return (
          <ProductInfoPage
            initialFormData={{
              Price: product?.Price?.Amount.toString() || '',
              Details: (product?.Details || {}) as BikeProduct
            }}
            component={CollectBikeInfo}
            onSubmit={onSubmit}
            hasError={() => true}
            onContinue={() => {
              setShowNewPremium(true);
              setShowEndorsementFlow(false);
            }}
          />
        );

      case PolicyType.minicoJewelry:
      case PolicyType.chubbJewelry:
        return (
          <ProductInfoPage
            initialFormData={{
              Price: product?.Price?.Amount.toString() || '',
              Name: product?.Name || '',
              Description: product?.Description || '',
              Details: (product?.Details || {}) as JewelryProduct
            }}
            component={CollectJewelryInfo}
            onSubmit={onSubmit}
            hasError={() => true}
            onContinue={() => {
              setShowNewPremium(true);
              setShowEndorsementFlow(false);
            }}
          />
        );

      case PolicyType.worthAveElectronics:
        return (
          <ProductInfoPage
            initialFormData={{
              Price: product?.Price?.Amount?.toString() || '',
              Details: (product?.Details || {}) as ElectronicsProduct,
              ProductType: [ElectronicsType.iPhone, ElectronicsType.smartPhone].includes(
                policy.InsuredItems[0]?.Details?.Type as ElectronicsType
              )
                ? ProductType.phone
                : ProductType.electronics
            }}
            component={CollectElectronicsInfo}
            onSubmit={onSubmit}
            hasError={() => true}
            onContinue={() => {
              setShowNewPremium(true);
              setShowEndorsementFlow(false);
            }}
          />
        );
    }
  };

  React.useEffect(() => {
    setPolicy(policy);
  }, [policy]);

  const onSubmit = (product) =>
    getEndorsementPremium(policy.ID, product).then(
      ({ CurrentPrice, PendingPrice, EndorsementPrice }) => {
        setPendingPrice(PendingPrice);
        setCurrentPrice(CurrentPrice);
        setEndorsementPrice(EndorsementPrice);
        setProduct(product);
        return { Policy: policy };
      }
    );

  return (
    <>
      <SlideOut
        showing={showEndorsementFlow || showNewPremium}
        onClose={() => {
          setShowEndorsementFlow(false);
          setShowNewPremium(false);
          setProduct({} as Product);
        }}
        onBack={() => {
          setShowEndorsementFlow(false);
          setShowNewPremium(false);
          setProduct({} as Product);
        }}
        title="Add a new item to your policy"
      >
        <ErrorBoundary forceMobile>
          <PageSection noPadding noBorder>
            {showEndorsementFlow && getPolicyForm()}
            {showNewPremium && (
              <div style={{ paddingTop: '24px' }}>
                <>
                  You're about to add a <b>{product.Name}</b> with a total insured value of{' '}
                  <b>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'usd',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(product.Price.Amount)}
                  </b>{' '}
                  to your policy. If you add this item, your policy premium will change to the new
                  amount below. Our team may reach out to you if further information is required.
                </>
                <PolicyStatement
                  title={'New Policy Premium'}
                  total={
                    endorsementPrice?.Amount || pendingPrice?.Amount || currentPrice?.Amount || 0
                  }
                >
                  <PolicyLineItem
                    key={'Current Premium'}
                    title={'Current Premium'}
                    description={'This is the current premium you pay for your policy.'}
                    total={currentPrice?.Amount || 0}
                  />
                  {pendingPrice?.Amount &&
                    currentPrice?.Amount &&
                    pendingPrice.Amount - currentPrice.Amount > 0 && (
                      <PolicyLineItem
                        key={'Pending Premium'}
                        title={'Pending Premium'}
                        description={
                          'This is the additional cost for all other pending items on your policy.'
                        }
                        total={pendingPrice.Amount - currentPrice.Amount}
                      />
                    )}
                  {endorsementPrice?.Amount && (pendingPrice?.Amount || currentPrice?.Amount) && (
                    <PolicyLineItem
                      key={'Additional Endorsement Cost'}
                      title={'Additional Endorsement Cost'}
                      description={
                        'This is the additional cost of adding this item to your policy.'
                      }
                      total={
                        endorsementPrice.Amount -
                        (pendingPrice?.Amount || currentPrice?.Amount || 0)
                      }
                    />
                  )}
                </PolicyStatement>
                <ButtonContainer center>
                  <Button
                    leftIcon={<IoArrowBack />}
                    onClick={() => {
                      setShowEndorsementFlow(true);
                      setShowNewPremium(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    primary
                    loading={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      addEndorsement(policy.ID, product).then(({ Policy }) => {
                        setPolicy(Policy);
                        setLoading(false);
                        setShowNewPremium(false);
                        setProduct({} as Product);
                      });
                    }}
                  >
                    Add Item
                  </Button>
                </ButtonContainer>
              </div>
            )}
          </PageSection>
        </ErrorBoundary>
      </SlideOut>

      <SlideOut
        showing={showSerialNumberEditor}
        onClose={closeSerialNumberSlideOut}
        onBack={closeSerialNumberSlideOut}
        title={'Serial Number'}
      >
        <ErrorBoundary forceMobile>
          <SerialNumberInput policy={policy} onPolicyUpdated={setPolicy} />
        </ErrorBoundary>
      </SlideOut>

      <BoxContainer>
        {policy.InsuredItems.map((item) => (
          <InsuredItem
            key={item.ID}
            policyType={policy.Type}
            item={item}
            onCancelEndorsement={() => onCancelEndorsement(item.ID || '')}
            onAddSerialNumber={() => onAddSerialNumber(policy, item.ID || '')}
          />
        ))}
      </BoxContainer>
      {policy.Type != PolicyType.chubbJewelry && (
        <ButtonContainer>
          <Button primary onClick={() => setShowEndorsementFlow(true)}>
            Add an item to this policy
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};

const CoveragePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 10px;

  .coverage-page-section-coverage-item--container {
    max-width: 100%;
  }
`;

const PolicyDetails = (props: { policy: Policy }): JSX.Element => {
  const [policy, setPolicy] = React.useState(props.policy);
  const totalItems = [
    ...getPolicyCoverageItemsLongDescription(policy.Type).map((item) => (
      <CoverageItem key={item.title} {...item} />
    )),
    ...policy.Coverage.Options.filter((item) => item.Enabled).map((item) => (
      <CoverageItem
        key={item.ID}
        title={getPolicyOptionalCoverageItem(item.ID).title}
        description={
          getPolicyOptionalCoverageItem(item.ID).description +
          ((item.Option as BooleanOption)?.ValueLabel
            ? " You're covered up to " + (item.Option as BooleanOption).ValueLabel + '.'
            : '')
        }
      />
    ))
  ];

  React.useEffect(() => {
    setPolicy(policy);
  }, [policy]);

  return (
    <BoxContainer>
      <BoxItem title="What's covered" className="coverage-box-item">
        <CoveragePageContainer title="What's covered">{totalItems}</CoveragePageContainer>
      </BoxItem>
    </BoxContainer>
  );
};

const Setting = (props: React.PropsWithChildren<{ title: string; description: string }>) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
    <div>
      <h3 style={{ fontWeight: 500, fontSize: '1em', margin: '0' }}>{props.title}</h3>
      <p style={{ fontSize: '0.9em', color: '#666666', margin: '5px 0px 0px 0px' }}>
        {props.description}
      </p>
    </div>
    <div>{props.children}</div>
  </div>
);

const PolicySettings = (props: { policy: Policy }) => {
  const [policy, setPolicy] = React.useState(props.policy);
  const [loading, setLoading] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    setPolicy(props.policy);
  }, [props.policy]);

  const onUpdate = async (updatedPolicy: Policy) => {
    // Store the original policy so we can revert to it in case of an error
    const origPolicy = { ...policy };

    // Set the loading flag and tentatively update the policy with the
    // desired changes.
    setLoading(true);
    setUpdated(false);
    setError(undefined);
    setPolicy(updatedPolicy);

    try {
      // Update the policy and set the policy based on the backend response,
      // which may have rejected the update.
      const res = await updatePolicyActivation(policy.ID, updatedPolicy);
      setPolicy(res.Policy);
      setUpdated(true);
    } catch (e) {
      // If there was an error, revert to the original policy
      const err = WrappedError.asWrappedError(e);
      setPolicy(origPolicy);
      setError(err.message);
    } finally {
      // In any case, set the loading flag to false
      setLoading(false);
    }
  };

  return (
    <TwoPaneContainer rightPane={<></>}>
      <h2>Policy Renewal</h2>
      <Setting
        title="Automatically Renew"
        description="If enabled, your policy will automatically be renewed 7 days before it expires. You can disable this any time before the renewal date to prevent your policy from renewing."
      >
        <FormSwitch
          enabled={!policy.DisableAutoRenew}
          loading={loading}
          onChange={() => onUpdate({ ...policy, DisableAutoRenew: !policy.DisableAutoRenew })}
        />
      </Setting>
      {(updated || error) && (
        <div style={{ padding: '10px 0px 0px 0px', fontSize: '0.8em' }}>
          {updated && (
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center', color: '#999999' }}>
              <IoCheckmarkSharp />
              <div>Saved!</div>
            </div>
          )}
          {error && (
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center', color: '#d1344b' }}>
              <IoAlertCircleSharp />
              <div>{error}</div>
            </div>
          )}
        </div>
      )}
    </TwoPaneContainer>
  );
};

const PolicyNavigationContainer = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0 0 0 0;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  box-shadow: 0 1px 0 0 #f6f6f6;
`;

const PolicyNavigationItemContainer = styled.li`
  display: block;
  text-align: center;

  a,
  a:visited {
    display: block;
    height: 100%;
    padding: 0px 0px 10px 0px;
    box-sizing: border-box;
    font-size: 0.9em;
    color: #666666;
    text-decoration: none;
  }

  a.active {
    color: #0ea5e9;
    font-weight: bold;
    box-shadow: 0 2px 0 0 #0ea5e9;
  }
`;

export const PolicyPage = (props: { policy: Policy; account: AccountSummary }) => {
  const { url, path } = useRouteMatch();
  const [policy, setPolicy] = React.useState(props.policy);

  React.useEffect(() => {
    setPolicy(props.policy);
  }, [props.policy]);

  return (
    <PageSection>
      <BikeSerialNumberBanner policies={[policy]} />
      <h1>{getPolicyDisplayName(policy)} Insurance</h1>
      <nav>
        <PolicyNavigationContainer>
          <PolicyNavigationItemContainer>
            <NavLink exact to={`${url.replace(/\/+$/, '')}/overview`}>
              Overview
            </NavLink>
          </PolicyNavigationItemContainer>
          <PolicyNavigationItemContainer>
            <NavLink exact to={`${url.replace(/\/+$/, '')}/items`}>
              Covered Items
            </NavLink>
          </PolicyNavigationItemContainer>
          <PolicyNavigationItemContainer>
            <NavLink exact to={`${url.replace(/\/+$/, '')}/summary`}>
              Policy Summary
            </NavLink>
          </PolicyNavigationItemContainer>
          <PolicyNavigationItemContainer>
            <NavLink exact to={`${url.replace(/\/+$/, '')}/settings`}>
              Settings
            </NavLink>
          </PolicyNavigationItemContainer>
        </PolicyNavigationContainer>
      </nav>
      <div style={{ padding: '10px 0px 0px 0px' }}>
        <Switch>
          <Route path={`${path.replace(/\/+$/, '')}/overview`}>
            <PolicyOverview policy={policy} account={props.account} />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/summary`}>
            <PolicyDetails policy={policy} />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/items`}>
            <PolicyCoveredItems policy={policy} onPolicyUpdate={setPolicy} />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/items/:item_id/serialnumber`}>
            <PolicyCoveredItems policy={policy} onPolicyUpdate={setPolicy} />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/settings`}>
            <PolicySettings policy={policy} />
          </Route>
          <Redirect to={`${path.replace(/\/+$/, '')}/overview`}></Redirect>
        </Switch>
      </div>
    </PageSection>
  );
};
