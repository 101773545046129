import * as React from 'react';
import styled from 'styled-components';
import { IoArrowForward } from 'react-icons/io5';

import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { PageSection } from '@oysterjs/ui/Page';
import { Select } from '@oysterjs/ui/Form/select';
import { Slider } from '@oysterjs/ui/Form/range';
import { Switch } from '@oysterjs/ui/Form/switch';

const CoverageSectionContainer = styled.div`
  padding: 0px 0px 5px 0px;
`;

const CoverageSectionTitleContainer = styled.div`
  h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }

  p {
    color: #999999;
    font-size: 0.9em;
    padding: 0;
    margin: 5px 0px 0px 0px;
  }
`;

const CoverageSection = (
  props: React.PropsWithChildren<{ title: string; description?: string }>
) => (
  <CoverageSectionContainer>
    <CoverageSectionTitleContainer>
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </CoverageSectionTitleContainer>
    {props.children}
  </CoverageSectionContainer>
);

const EndorsementsPanelContainer = styled.div<{ small?: boolean }>`
  width: ${(props) => (props.small ? '40%' : '54%')};
  max-width: ${(props) => (props.small ? '360px' : '500px')};
`;

const EndorsementsPanel = (props: React.PropsWithChildren<{ title: string; small?: boolean }>) => (
  <EndorsementsPanelContainer small={props.small}>
    <h2>{props.title}</h2>
    {props.children}
  </EndorsementsPanelContainer>
);

const Endorsements = styled.div`
  display: flex;
  margin: 20px 0px;
  gap: 0px 6.5%;
`;

const PurchaseSection = styled.div`
  padding: 0px 0px 20px 0px;
`;

export const EditPolicyPage = (): JSX.Element => {
  return (
    <>
      <PageSection>
        <h1>Edit your bike policy</h1>
        <p>
          Life happens, we get it! Oyster policies are flexible and easy-to-change, so that they can
          be adjusted according to your changing needs. Modify any aspect of your policy and get an
          instant price adjustment. If you're not sure what kind of coverage you need, our AI
          Coverage Assistant can help!
        </p>
        <ButtonContainer>
          <Button primary icon={<IoArrowForward />}>
            Launch AI Coverage Assistant
          </Button>
        </ButtonContainer>

        <Endorsements>
          <EndorsementsPanel title="Coverage">
            <CoverageSection
              title="Bicycle Damage"
              description="Bicycle damage covers any physical damage to your bicycle. When choosing a coverage level, consider the value of your bike and any potential replacement parts."
            >
              <Slider
                min={1000}
                max={10000}
                initialValue={7500}
                interval={250}
                formatter={(val: number) =>
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0
                  }).format(val)
                }
              />
            </CoverageSection>

            <CoverageSection
              title="Liability Protection"
              description="Liability protection applies when your bicycle causes damage to others' property. Consider where you ride your bike and where you live when choosing a coverage level."
            >
              <Slider
                min={5000}
                max={25000}
                initialValue={20000}
                interval={1000}
                formatter={(val: number) =>
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0
                  }).format(val)
                }
              />
            </CoverageSection>

            <CoverageSection
              title="Medical Payments"
              description="Medical coverage applies when someone, including you, incurs physical injury as a result of operating your bike."
            >
              <Slider
                min={1000}
                max={10000}
                initialValue={5000}
                interval={250}
                formatter={(val: number) =>
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0
                  }).format(val)
                }
              />
            </CoverageSection>

            <CoverageSection
              title="Cycle Apparel"
              description="Cycle apparel covers theft, loss, and damage to any bicycle accessories you own, such as lights, bells, water bottles, locks, and helmets."
            >
              <Slider
                min={100}
                max={1000}
                initialValue={400}
                interval={100}
                formatter={(val: number) =>
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 0
                  }).format(val)
                }
              />
            </CoverageSection>
          </EndorsementsPanel>

          <EndorsementsPanel small title="Add-ons">
            <CoverageSection
              title="Race Cover"
              description="Add additional coverage for using your bike at racing and other competitive events."
            >
              <Switch />
            </CoverageSection>

            <CoverageSection
              title="Extra Geo Cover"
              description="Add additional coverage in extended geographical ranges of if you're traveling."
            >
              <Switch enabled />
            </CoverageSection>

            <h2>Options</h2>
            <CoverageSection
              title="Usage Type"
              description="Describe how you use your bike for a more accurate price."
            >
              <div style={{ maxWidth: '200px', paddingBottom: '20px', paddingTop: '10px' }}>
                <Select
                  options={[
                    { value: '1', displayValue: 'Casual' },
                    { value: '2', displayValue: 'Fitness' },
                    { value: '3', displayValue: 'Commuter' },
                    { value: '4', displayValue: 'Competitive' }
                  ]}
                />
              </div>
            </CoverageSection>

            <CoverageSection
              title="Claim Basis"
              description="Choose how you want claims to be settled."
            >
              <div style={{ maxWidth: '200px', paddingBottom: '20px', paddingTop: '10px' }}>
                <Select
                  options={[
                    { value: '1', displayValue: 'Replacement Cost' },
                    { value: '2', displayValue: 'Actual Value' }
                  ]}
                />
              </div>
            </CoverageSection>
          </EndorsementsPanel>
        </Endorsements>
        <PurchaseSection>
          <ButtonContainer>
            <Button primary>Save (+${getV()}/yr)</Button>
            <Button>Cancel</Button>
          </ButtonContainer>
        </PurchaseSection>
      </PageSection>
    </>
  );
};

const getV = () => Math.round(Math.random() * 50) + Math.round(Math.random() * 4) / 4;
