import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  IoArrowForward,
  IoCalendarClear,
  IoCloudUpload,
  IoDocumentText,
  IoFlag,
  IoLocation,
  IoPencil,
  IoTrailSign,
  IoTrashOutline
} from 'react-icons/io5';

import {
  createClaim,
  deleteClaim,
  getClaim,
  getClaims,
  resetClaim,
  submitClaim,
  updateClaim
} from '@oysterjs/core/api/claims';
import { Button, ButtonContainer, ButtonLink, UnstyledButton } from '@oysterjs/ui/Button';
import { PageSection, TwoPaneContainer } from '@oysterjs/ui/Page';
import { Table } from '@oysterjs/ui/Table';
import { VerticalLayout, VerticalLayoutItem } from '@oysterjs/ui/VerticalLayout';
import { useDynamicRefs } from '@oysterjs/core/dynamicRefs';
import { useWindowDimensions, useWindowScrollPosition } from '@oysterjs/core/window';
import {
  Claim,
  ClaimSection,
  ClaimSectionItemType,
  ClaimState,
  ClaimType,
  LocationItem,
  LongTextItem,
  Policy,
  PolicyState,
  SelectItem,
  UploadItem,
  ValidationError,
  VerificationStatus
} from '@oysterjs/types';

import {
  CheckboxSection,
  ConfirmSection,
  DateSection,
  ErrorDisplay,
  getData,
  getFieldKey,
  LocationSection,
  LongTextSection,
  ProductSection,
  SelectSection,
  TextSection,
  UploadSection
} from './sections';
import { Attachment } from './attachment';
import { getMapURL } from '@oysterjs/core/mapbox';
import { ClaimSectionItemFunction } from './sections/section';
import { Modal } from '@oysterjs/ui/Modal';
import { Badge } from '@oysterjs/ui/Badge/index';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import { VerifyIdentityBanner } from '../account';
import { Loadable } from '@oysterjs/ui/Loadable';
import { getAccountSummary } from '@oysterjs/core/api/user';

const HeaderContainer = styled.div`
  display: flex;

  h1 {
    width: 100%;
  }
`;

const ClaimActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-width: 150px;
`;

const getClaimBadge = (state: ClaimState): JSX.Element => {
  switch (state) {
    case ClaimState.collectingInformation:
    case ClaimState.unknown:
      return <Badge label="Collecting information" color="#e6e6e6" />;
    case ClaimState.awaitingProcessing:
      return <Badge label="Waiting for approval" color="rgba(252, 227, 193)" />;
    case ClaimState.denied:
      return <Badge label="Rejected" color="rgb(255, 193, 185)" />;
    case ClaimState.settled:
      return <Badge label="Approved" color="rgba(189, 234, 205)" />;
    default:
      return <Badge label="Unknown" color="#e6e6e6" />;
  }
};

const relativeTimeString = (start: Date, end: Date): string => {
  const formatter = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
  const units = [
    { div: 1000, unit: 'seconds' },
    { div: 60, unit: 'minutes' },
    { div: 60, unit: 'hours' },
    { div: 24, unit: 'days' },
    { div: 30, unit: 'months' },
    { div: 12, unit: 'years' },
    { div: 1000, unit: '' }
  ];

  // start with time difference in milliseconds
  let diff = start.getTime() - end.getTime();

  // iteratively divide by the divisor of each unit while there
  // is a whole next unit
  for (let i = 0; i < units.length; i++) {
    diff /= units[i].div;
    if (Math.abs(diff) < units[i + 1].div) {
      // We need the RelativeTimeFormatUnit type to make this typecheck
      // but that type definition isn't available (hence the any, hence
      // the eslint disable).
      // eslint-disable-next-line
      return formatter.format(Math.floor(diff), units[i].unit as any);
    }
  }

  return 'a long time ago';
};

const ClaimsTable = (props: { claims: Claim[] }) => {
  const [claims, setClaims] = React.useState(props.claims);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();

  React.useEffect(() => {
    setClaims(props.claims);
  }, [props.claims]);

  const [showDeleteClaimConfirmation, setShowDeleteClaimConfirmation] = React.useState('');
  const history = useHistory();

  return (
    <>
      {showDeleteClaimConfirmation && (
        <Modal
          style={{ maxWidth: '500px' }}
          onClose={() => setShowDeleteClaimConfirmation('')}
          headerContents={<h3 style={{ margin: 0 }}>Confirm delete</h3>}
        >
          <p style={{ textAlign: 'center' }}>
            Are you sure you want to delete this claim? This cannot be undone.
          </p>
          <ButtonContainer center>
            <Button onClick={() => setShowDeleteClaimConfirmation('')} disabled={loading}>
              Cancel
            </Button>
            <Button
              primary
              icon={<IoArrowForward />}
              loading={loading}
              onClick={() => {
                setLoading(true);
                deleteClaim(showDeleteClaimConfirmation)
                  .then(getClaims)
                  .then(({ Claims }) => setClaims(Claims))
                  .then(() => setShowDeleteClaimConfirmation(''))
                  .catch((err) => setError(err.message))
                  .finally(() => setLoading(false));
              }}
            >
              Confirm
            </Button>
          </ButtonContainer>
          {error && <ErrorDisplay style={{ textAlign: 'center' }}>{error}</ErrorDisplay>}
        </Modal>
      )}
      <Table>
        <thead>
          <tr>
            <td>Insured Item</td>
            <td>Status</td>
            <td>Last Updated</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {claims.map((claim) => (
            <tr
              key={claim.ID}
              onClick={() => history.push(`/claims/${claim.ID}`)}
              style={{ cursor: 'pointer' }}
            >
              <td className="labeled">
                <div>{claim.Product?.Name}</div>
                <div>{claim.Types?.join(', ') || 'Unknown'}</div>
              </td>
              <td style={{ verticalAlign: 'top' }} className="status">
                {getClaimBadge(claim.State)}
              </td>
              <td style={{ verticalAlign: 'top' }}>
                {relativeTimeString(new Date(claim.UpdatedAt), new Date())}
              </td>
              <td style={{ textAlign: 'right', verticalAlign: 'top' }}>
                <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
                  {claim.State === ClaimState.collectingInformation && (
                    <UnstyledButton
                      onClick={(e) => {
                        setShowDeleteClaimConfirmation(claim.ID);
                        e.stopPropagation();
                      }}
                      aria-label="Delete Claim"
                    >
                      <IoTrashOutline style={{ fontSize: '1.3em', color: '#666666' }} />
                    </UnstyledButton>
                  )}
                  <UnstyledButton
                    onClick={(e) => {
                      history.push(`/claims/${claim.ID}`);
                      e.stopPropagation();
                    }}
                    aria-label="View or Edit Claim"
                  >
                    <IoArrowForward style={{ fontSize: '1.3em', color: '#666666' }} />
                  </UnstyledButton>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export const ResumeClaimsPage = (props: { claims: Claim[] }): JSX.Element => {
  const history = useHistory();
  const startClaim = () => history.push(`/claims/start`);

  const claimsPhrase = props.claims.length === 1 ? 'a claim' : 'some claims';
  return (
    <PageSection>
      <HeaderContainer>
        <h1>Resume a claim?</h1>
        <ClaimActionsContainer>
          <Button onClick={startClaim} primary icon={<IoArrowForward />}>
            Start a claim anyway
          </Button>
        </ClaimActionsContainer>
      </HeaderContainer>
      <p>
        It looks like you already have {claimsPhrase} open. Would you like to resume or close one of
        those instead? You can still create a new claim if this claim is for an different insured
        item.
      </p>
      <ClaimsTable claims={props.claims} />
    </PageSection>
  );
};

export const ClaimsPage = (props: { claims: Claim[]; policies: Policy[] }): JSX.Element => {
  const policies = props.policies.filter((p) => p.State === PolicyState.policyInforce);

  return (
    <PageSection>
      <Loadable request={getAccountSummary()}>
        {(res) => {
          const hasNoClaims = (props.claims?.length || 0) === 0;
          const requireVerification = res.Verification?.Status !== VerificationStatus.Verified;
          const verificationPending = res.Verification?.Status === VerificationStatus.Processing;

          const shouldDisableClaimCTA = policies.length === 0 || verificationPending;
          let startClaimCTALabel = 'Start a claim';
          if (verificationPending) {
            startClaimCTALabel = 'Identity verification in progress';
          } else if (requireVerification) {
            startClaimCTALabel = 'Verify your identity to start a claim';
          }

          const getClaimURL = (): string => {
            if (requireVerification) {
              return '/account/profile';
            }

            if (hasNoClaims) {
              return '/claims/start';
            } else {
              // Check if there is an existing claim
              if (
                props.claims.find(
                  (c) => c.State != ClaimState.denied && c.State != ClaimState.settled
                )
              ) {
                return '/claims/resume';
              } else {
                return '/claims/start';
              }
            }
          };

          return (
            <>
              <VerifyIdentityBanner accountSummary={res} />
              <HeaderContainer>
                <h1>Your Claims</h1>
                {(props.claims?.length || 0) > 0 && (
                  <ClaimActionsContainer>
                    <ButtonLink
                      primary
                      disabled={shouldDisableClaimCTA}
                      icon={
                        policies.length > 0 && !shouldDisableClaimCTA ? (
                          <IoArrowForward />
                        ) : undefined
                      }
                      href={getClaimURL()}
                    >
                      {policies.length > 0 ? startClaimCTALabel : 'No active policies'}
                    </ButtonLink>
                  </ClaimActionsContainer>
                )}
              </HeaderContainer>
              {hasNoClaims && (
                <div>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img src="/images/no_claims.svg" style={{ width: 'max(250px, 60%)' }} />
                    <p style={{ color: '#999999' }}>You don't have any claims.</p>
                    <ButtonContainer center>
                      <ButtonLink
                        primary
                        disabled={shouldDisableClaimCTA}
                        icon={
                          policies.length > 0 && !shouldDisableClaimCTA ? (
                            <IoArrowForward />
                          ) : undefined
                        }
                        href={getClaimURL()}
                      >
                        {policies.length > 0 ? startClaimCTALabel : 'No active policies'}
                      </ButtonLink>
                    </ButtonContainer>
                  </div>
                </div>
              )}
              {(props.claims?.length || 0) > 0 && <ClaimsTable claims={props.claims} />}
            </>
          );
        }}
      </Loadable>
    </PageSection>
  );
};

const ClaimSectionOuter = (
  props: React.PropsWithChildren<{
    active: boolean;
    icon: JSX.Element;
    title: string;
    description?: string;
  }>
) => (
  <VerticalLayoutItem
    icon={props.icon}
    style={{
      minHeight: 'calc(75vh - 100px)',
      paddingBottom: '100px'
    }}
    inactive={!props.active}
  >
    <h1 style={{ marginTop: '0' }}>{props.title}</h1>
    {props.description && <p>{props.description}</p>}
    {props.children}
  </VerticalLayoutItem>
);

const ClaimSectionItem = (
  props: React.PropsWithChildren<{
    subTitle?: string;
    description?: string;
  }>
) => (
  <div style={{ padding: '10px 0px' }}>
    {props.subTitle && (
      <h2 style={{ color: 'black', fontSize: '1.2em', fontWeight: 500, marginBottom: '0' }}>
        {props.subTitle}
      </h2>
    )}
    {props.description && (
      <p style={{ fontSize: '0.9em', color: '#666666', marginTop: '5px' }}>{props.description}</p>
    )}
    {props.children}
  </div>
);

interface ClaimPageProps {
  claim?: Claim;
  policies: Policy[];
}

export const ClaimPage = (props: ClaimPageProps): JSX.Element => {
  const { scrollPosY } = useWindowScrollPosition();
  const { innerHeight } = useWindowDimensions();
  const history = useHistory();

  // loading indicates that a request is in progress. all elements will respond
  // to this state by preventing data entry.
  const [loading, setLoading] = React.useState(false);

  // claim stores the current claim object, starting with the initial claim. this
  // is updated after each section is completed.
  const [claim, setClaim] = React.useState(props.claim);

  // validationError stores any validation error that may have occured, while editing
  // an in-progress claim or at claim submission. Claim sections will receive this
  // if the `Field` property matches the section's ID.
  const [validationError, setValidationError] = React.useState<ValidationError | undefined>();

  // dynamic refs hook let us dynamically create and retrieve references to the
  // dom, without knowing in advance how many refs we need. this is particularly
  // useful here because we render claim sections based on the claim object, which
  // can vary, and then use the refs to scroll between sections.
  const { getRef, setRef } = useDynamicRefs<HTMLDivElement>();

  // sections stores each claim section that is displayed on the page. this is updated
  // any time the claim object changes.
  const [sections, setSections] = React.useState<{ key: string; section: JSX.Element }[]>([]);

  // sectionContinued indicates the key of the section that the user last completed
  // and clicked "continue" (or equivalent option) on. This is then used to scroll to
  // the next section.
  const [sectionContinued, setSectionContinued] = React.useState('');

  // computes the key of the section `offset` away from the section given by
  // `section`. It will return the empty string if that section does not exist.
  const sectionAtOffset = (section: string, offset: number): string => {
    const sectionIndex = sections.findIndex((s) => s.key === section);
    const nextSectionKey = sections[sectionIndex + offset]?.key;
    return nextSectionKey || '';
  };

  // determines if a given section is active or not
  const isInactive = (sectionKey: string) => {
    const bottom = scrollPosY + innerHeight;

    const sectionIndex = sections.findIndex((s) => s.key === sectionKey);
    const elem = getRef(sectionKey)?.current?.offsetTop || 0;

    const nextSectionKey = sections[sectionIndex + 1]?.key;
    const nextElem = getRef(nextSectionKey)?.current?.offsetTop || bottom;

    if (sectionIndex <= 0) {
      return elem < scrollPosY;
    }

    if (elem < scrollPosY && nextElem < bottom) {
      return true;
    }

    return !isInactive(sections[sectionIndex - 1].key);
  };

  // sets update the local claim object with the given field and data, and then
  // performs a remote update if specified.
  const setData = (section: ClaimSection, field: string, value: string, updateClaim: boolean) => {
    if (field === 'Types') {
      setClaim((prev) => {
        const newClaim = {
          ...(prev as Claim),
          Types: value.split(',') as ClaimType[]
        };

        if (updateClaim) {
          doUpdateClaim(section.ID, newClaim);
        }

        return newClaim;
      });
    } else {
      setClaim((prev) => {
        const newClaim = {
          ...(prev as Claim),
          [field]: value,
          Data: {
            ...(prev?.Data || {}),
            [field]: value
          }
        };

        if (updateClaim) {
          doUpdateClaim(section.ID, newClaim);
        }

        return newClaim;
      });
    }
  };

  // updates the given claim and sets the section that was just completed, if successful
  const doUpdateClaim = (sectionKey: string, claim: Claim) => {
    setLoading(true);
    return updateClaim(claim)
      .then((data) => {
        // don't set an error if the sections have changed
        const currentSections = claim.Sections.flatMap((s) => s.Items.map((i) => i.SubField));
        const newSections = data.Claim.Sections.flatMap((s) => s.Items.map((i) => i.SubField));
        const equal =
          currentSections.length === newSections.length &&
          newSections.reduce((prev, curr, i) => prev && curr === currentSections[i], true);

        if (!equal || data.NextError?.Field !== sectionKey) {
          setValidationError(undefined);
          setSectionContinued(sectionKey);
        } else {
          const prevSection = sectionAtOffset(sectionKey, -1);
          setValidationError(data.NextError);
          if (prevSection) {
            setSectionContinued(prevSection);
          }
        }

        setClaim(data.Claim);
      })
      .catch((err) => {
        setSectionContinued(sectionKey);
        setValidationError({
          Field: sectionKey,
          SubField: '',
          Message: err.message || err.toString()
        });
      })
      .finally(() => setLoading(false));
  };

  // submits the claim
  const doSubmit = (section: ClaimSection) => {
    setLoading(true);
    submitClaim(claim?.ID || '')
      .then(({ Claim }) => {
        setValidationError(undefined);
        setClaim(Claim);
      })
      .catch((e) => {
        const err = WrappedError.asWrappedError(e);
        if (err.type() === ErrorType.validationError) {
          const sectionKey = sectionAtOffset(err.metadata().Field, -1);
          setValidationError(err.getValidationError());
          if (sectionKey) {
            setSectionContinued(sectionKey);
          }
        } else {
          setValidationError({
            Field: section.Field,
            SubField: '',
            Message: err.message
          });
        }
      })
      .finally(() => setLoading(false));
  };

  // creates or resets a claim, depending on the current claim state.
  const createOrResetClaim = (product) => {
    const policyId =
      props.policies.find((p) => !!p.InsuredItems.find((pr) => pr.ID === product.ID))?.ID || '';
    const productId = product.ID || '';
    setLoading(true);

    if (claim) {
      resetClaim(claim.ID, policyId, productId)
        .then(({ Claim }) => setClaim(Claim))
        .then(() => setSectionContinued('start'))
        .then(() => setValidationError(undefined))
        .catch((err) =>
          setValidationError({
            Field: 'start',
            Message: err.message
          })
        )
        .finally(() => setLoading(false));
    } else {
      createClaim(policyId, productId)
        .then(({ Claim }) => {
          history.replace('/claims/' + Claim.ID);
          setClaim(Claim);
        })
        .then(() => setSectionContinued('start'))
        .then(() => setValidationError(undefined))
        .catch((err) =>
          setValidationError({
            Field: 'start',
            Message: err.message
          })
        )
        .finally(() => setLoading(false));
    }
  };

  // creates a section based on a claim section description, creating all input
  // elements inside.
  const createSection = (section: ClaimSection) => {
    if (!claim) {
      return {
        key: section.ID,
        section: <></>
      };
    }

    const sectionIcons = {
      [ClaimSectionItemType.select]: <IoTrailSign />,
      [ClaimSectionItemType.date]: <IoCalendarClear />,
      [ClaimSectionItemType.location]: <IoLocation />,
      [ClaimSectionItemType.longText]: <IoDocumentText />,
      [ClaimSectionItemType.upload]: <IoCloudUpload />,
      [ClaimSectionItemType.confirm]: <IoPencil />
    };

    const sectionTypeMap: Record<ClaimSectionItemType, ClaimSectionItemFunction> = {
      [ClaimSectionItemType.checkbox]: CheckboxSection,
      [ClaimSectionItemType.select]: SelectSection,
      [ClaimSectionItemType.text]: TextSection,
      [ClaimSectionItemType.date]: DateSection,
      [ClaimSectionItemType.location]: LocationSection,
      [ClaimSectionItemType.longText]: LongTextSection,
      [ClaimSectionItemType.upload]: UploadSection,
      [ClaimSectionItemType.confirm]: ConfirmSection
    };

    const sectionItems = section.Items.map((sectionItem) => ({
      sectionItem,
      data: sectionTypeMap[sectionItem.Type]
    }));

    const needsContinueButton = sectionItems.findIndex((item) => !item.data.autoContinue) >= 0;
    const needsSubmitButton = sectionItems.findIndex((item) => !!item.data.submit) >= 0;

    return {
      key: section.ID,
      section: (
        <div key={section.ID} ref={setRef(section.ID)}>
          <ClaimSectionOuter
            active={!isInactive(section.ID)}
            icon={sectionIcons[section.Items[0].Type || ''] || <IoFlag />}
            title={section.Title}
            description={section.Description}
          >
            {sectionItems.map((item) => (
              <ClaimSectionItem
                subTitle={item.sectionItem.SubTitle}
                description={item.sectionItem.Description}
                key={section.ID + '.' + item.sectionItem.SubField}
              >
                {React.createElement(item.data.component, {
                  claim,
                  section,
                  sectionItem: item.sectionItem,
                  loading,
                  setData: (field: string, value: string) =>
                    setData(section, field, value, !!item.data.autoContinue),
                  validationError:
                    validationError?.Field === section.Field &&
                    validationError?.SubField === item.sectionItem.SubField &&
                    item.sectionItem.SubField
                      ? validationError
                      : undefined,
                  updateClaim: () => getClaim(claim.ID || '').then((d) => d.Claim)
                })}
              </ClaimSectionItem>
            ))}
            {validationError?.Field === section.Field && !validationError?.SubField && (
              <ErrorDisplay>{validationError?.Message}</ErrorDisplay>
            )}
            {needsContinueButton && !needsSubmitButton && (
              <div style={{ paddingTop: '20px' }}>
                <ButtonContainer>
                  <Button
                    primary
                    icon={<IoArrowForward />}
                    disabled={
                      sectionItems.findIndex(
                        (item) =>
                          item.data
                            .getData(claim, section, item.sectionItem)
                            .findIndex((v) => !v) >= 0
                      ) >= 0
                    }
                    loading={loading}
                    onClick={() =>
                      doUpdateClaim(
                        section.ID,
                        sectionItems.reduce(
                          (prev, curr) => {
                            let next = { ...prev };
                            curr.data.onContinue?.(
                              claim,
                              section,
                              curr.sectionItem,
                              (field: string, value: string) => {
                                next = {
                                  ...next,
                                  [field]: value,
                                  Data: {
                                    ...(next.Data || {}),
                                    [field]: value
                                  }
                                };
                              }
                            );
                            return next;
                          },
                          { ...claim }
                        )
                      )
                    }
                  >
                    Continue
                  </Button>
                </ButtonContainer>
              </div>
            )}
            {needsSubmitButton && (
              <div style={{ paddingTop: '20px' }}>
                <ButtonContainer>
                  <Button
                    primary
                    icon={<IoArrowForward />}
                    loading={loading}
                    onClick={() => doSubmit(section)}
                  >
                    Submit Claim
                  </Button>
                </ButtonContainer>
              </div>
            )}
          </ClaimSectionOuter>
        </div>
      )
    };
  };

  // scrolls to the next section if a section was continued on and there is a next section
  React.useEffect(() => {
    setTimeout(() => {
      if (!sectionContinued) {
        return;
      }

      const nextSectionKey = sectionAtOffset(sectionContinued, 1);
      if (getRef(nextSectionKey)?.current?.offsetTop) {
        window.scrollTo({
          top: (getRef(nextSectionKey)?.current?.offsetTop || 0) - 120,
          behavior: 'smooth'
        });
      }

      setSectionContinued('');
    }, 10);
  }, [JSON.stringify([sectionContinued, validationError, ...sections.map((s) => s.key)])]);

  // creates the sections to render every time a meaningful update happens to the claim state
  React.useEffect(() => {
    setSections([
      {
        key: 'start',
        section: (
          <div key={'start'} ref={setRef('start')}>
            <ClaimSectionOuter
              active={!isInactive('start')}
              icon={<IoFlag />}
              title="Start a claim"
              description="Insurance is for protection and peace of mind, and the claim filing process should reflect that. To get started, select the insured item you want to file a claim for."
            >
              <ProductSection
                products={props.policies
                  .filter((policy) => policy.State === PolicyState.policyInforce)
                  .flatMap((policy) => policy.InsuredItems.filter((item) => !item.Pending))}
                selectedProductId={claim?.Product?.ID}
                loading={loading}
                onContinue={createOrResetClaim}
                validationError={validationError?.Field === 'start' ? validationError : undefined}
              />
            </ClaimSectionOuter>
          </div>
        )
      },
      ...(claim?.Sections || []).map(createSection)
    ]);
  }, [
    JSON.stringify([claim, loading, validationError, ...sections.map((s) => isInactive(s.key))])
  ]);

  // short circuit the rest of this component if the claim was updated and now is
  // in a non-editable state
  if (claim && claim.State !== ClaimState.collectingInformation) {
    return <ViewClaimPage claim={claim} />;
  }

  return (
    <PageSection>
      <div style={{ marginTop: '1em' }}>
        <VerticalLayout>{sections.map((s) => s.section)}</VerticalLayout>
      </div>
    </PageSection>
  );
};

const PropertyList = styled.ul`
  list-style: none;
  padding: 10px 0px;
  border-top: 2px solid #f2f2f2;
  font-size: 0.8em;
  color: #999999;
`;

const PropertyListItem = (props: { name: string; value: string }) => (
  <li style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px 0px 0px' }}>
    <span style={{ paddingRight: '10px' }}>{props.name}</span>
    <span style={{ textAlign: 'right' }}>{props.value}</span>
  </li>
);

const ViewClaimPage = (props: { claim: Claim }) => {
  return (
    <PageSection>
      <TwoPaneContainer
        leftPaneWidth={65}
        rightPane={
          <>
            <h2>Claim Summary</h2>

            <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
              <div>
                <h3 style={{ margin: '0' }}>Insured Product</h3>
                <p style={{ marginTop: '5px', marginBottom: '5px' }}>{props.claim.Product.Name}</p>
              </div>

              {props.claim.Sections.flatMap((section) =>
                section.Items.map((item) => {
                  switch (item.Type) {
                    case ClaimSectionItemType.select:
                      return (
                        <div>
                          <h3 style={{ margin: '0px' }}>{item.SubTitle || section.Title}</h3>
                          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                            {(section.Field === 'Types'
                              ? props.claim.Types
                              : [getData<string>(props.claim, section.Field, item.SubField) || '']
                            )
                              ?.map(
                                (t) =>
                                  (item.Item as SelectItem).AllowedValues.find(
                                    (tt) => tt.Value === t
                                  )?.Title || ''
                              )
                              .join(', ')}
                          </p>
                        </div>
                      );

                    case ClaimSectionItemType.text:
                      return (
                        <div>
                          <h3 style={{ margin: '0px' }}>{item.SubTitle || section.Title}</h3>
                          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                            {getData<string>(props.claim, section.Field, item.SubField)}
                          </p>
                        </div>
                      );

                    case ClaimSectionItemType.date:
                      return (
                        <div>
                          <h3 style={{ margin: '0px' }}>{item.SubTitle || section.Title}</h3>
                          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                            {new Date(props.claim.IncidentDate).toLocaleDateString()}
                          </p>
                        </div>
                      );

                    case ClaimSectionItemType.location:
                      return (
                        <div>
                          <h3 style={{ margin: '0px' }}>{item.SubTitle || section.Title}</h3>
                          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                            {
                              props.claim.Data[
                                getFieldKey(
                                  section.Field,
                                  item.SubField,
                                  (item.Item as LocationItem).AddressField
                                )
                              ]
                            }
                          </p>
                          <img
                            style={{
                              paddingTop: '5px',
                              width: '100%',
                              maxWidth: '400px',
                              borderRadius: '8px'
                            }}
                            src={getMapURL(
                              parseFloat(
                                props.claim.Data[
                                  getFieldKey(
                                    section.Field,
                                    item.SubField,
                                    (item.Item as LocationItem).LongitudeField
                                  )
                                ]
                              ),
                              parseFloat(
                                props.claim.Data[
                                  getFieldKey(
                                    section.Field,
                                    item.SubField,
                                    (item.Item as LocationItem).LatitudeField
                                  )
                                ]
                              ),
                              { zoomLevel: 13, height: 210, width: 300 }
                            )}
                          />
                        </div>
                      );

                    case ClaimSectionItemType.longText:
                      return (
                        <div>
                          <h3 style={{ margin: '0px' }}>{item.SubTitle || section.Title}</h3>
                          {props.claim.Data[
                            getFieldKey(
                              section.Field,
                              item.SubField,
                              (item.Item as LongTextItem).Field
                            )
                          ]
                            .trim()
                            .split('\n')
                            .map((paragraph) => (
                              <p key={paragraph} style={{ marginTop: '5px', marginBottom: '5px' }}>
                                {paragraph}
                              </p>
                            ))}
                        </div>
                      );

                    case ClaimSectionItemType.upload:
                      if (!props.claim.Attachments[getFieldKey(section.Field, item.SubField)]) {
                        return null;
                      }
                      return (
                        <div>
                          <h3 style={{ margin: '0px' }}>{(item.Item as UploadItem).Name}</h3>
                          <div
                            style={{
                              marginTop: '5px',
                              marginBottom: '5px',
                              padding: '10px 0px 5px 0px',

                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              gap: '5px'
                            }}
                          >
                            {props.claim.Attachments[getFieldKey(section.Field, item.SubField)].map(
                              (f) => (
                                <Attachment key={f.Name} url={f.URL} name={f.Name} size={f.Size} />
                              )
                            )}
                          </div>
                        </div>
                      );
                  }
                })
              )}

              <PropertyList>
                <PropertyListItem name="Claim" value={props.claim.ID} />
                <PropertyListItem name="Policy" value={props.claim.Policy.ID} />
                <PropertyListItem
                  name="Updated"
                  value={new Date(props.claim.UpdatedAt).toLocaleString()}
                />
              </PropertyList>
            </div>
          </>
        }
      >
        <h1>Your claim</h1>
        {getClaimBadge(props.claim.State)}
        <p>
          Your claim has been successfully submitted! You'll receive a response from the insurance
          carrier as soon as your claim is being processed. Please reach out to our team at
          support@withoyster.com if you have any questions in the meantime.
        </p>
        <img src="/images/celebrate.svg" style={{ width: '100%' }} />
      </TwoPaneContainer>
    </PageSection>
  );
};
