import styled from 'styled-components';

import { useWindowDimensions } from '@oysterjs/core/window';

import { DesktopNavBar } from './desktop';
import { MobileNavBar } from './mobile';

const TopBarContainer = styled.ol`
  display: flex;
  margin: 0;
  list-style: none;
  justify-content: space-between;
  border-bottom: 2px solid #fafafa;
  box-sizing: border-box;
  padding: 0px 40px;

  a,
  a:visited {
    color: #333333;
    text-decoration: none;
  }

  a.active-tab {
    color: #0ea5e9;
  }
`;

export const NavBar = () => (
  <nav aria-label="Main Menu">
    <TopBarContainer>
      {useWindowDimensions().innerWidth > 600 ? <DesktopNavBar /> : <MobileNavBar />}
    </TopBarContainer>
  </nav>
);
