import * as React from 'react';
import styled from 'styled-components';

export const BoxContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style: none;
  margin: 20px 0px;
  padding: 0px;
  gap: 40px;
`;

export const Box = styled.li`
  padding: 30px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  background: #fafafa;
  margin: 0;

  max-width: calc(33% - 20px);
  flex: 1 0 calc(33% - 30px);
  box-sizing: border-box;

  &.endorsement-price {
    max-width: calc(50% - 20px);
  }

  &.insured-item-box-item {
    width: 280px;
  }

  &.policy-box-item {
    width: 280px;
    display: flex;
    flex-direction: column;
  }

  &.coverage-box-item {
    max-width: 500px;
    width: 66%;
    flex: 1 0 calc(50% - 20px);
  }

  @media (max-width: 800px) {
    &.coverage-box-item {
      max-width: 100%;
      flex: 1 0 100%;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
    flex: 1 0 100%;

    &.insured-item-box-item {
      max-width: 340px;
    }

    &.policy-box-item {
      max-width: 340px;
    }
  }

  @media (max-width: 360px) {
    &.insured-item-box-item {
      width: 100%;
    }

    &.policy-box-item {
      width: 100%;
    }
  }
`;

export const BoxBadgeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
`;

export const BoxItemImage = styled.img`
  width: 100%;
  height: 180px;
  box-sizing: border-box;
  background: transparent;
  object-fit: contain;
  border-radius: 6px;
  margin-bottom: 20px;
`;

export const AlternateBoxTitle = styled.div`
  font-size: 1.4em;
  font-weight: 500;
`;

const BoxTitle = styled.div`
  font-size: 1em;
  font-weight: 500;
  padding: 0px 20px;
  box-sizing: border-box;
  color: #666666;
  text-align: center;
  width: 100%;
`;

const BoxContents = styled.div`
  padding: 20px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const BoxDescription = styled.div`
  text-align: center;
  width: 100%;
  font-size: 0.8em;
`;

export const LeftAlignBoxContents = styled.div`
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  height: 100%;
`;

export const BoxItem = (
  props: React.PropsWithChildren<{
    title: string;
    description?: string | JSX.Element;
    className?: string;
  }>
) => (
  <Box className={props.className}>
    <BoxTitle>{props.title}</BoxTitle>
    <BoxContents>
      {props.children}
      {props.description && <BoxDescription>{props.description}</BoxDescription>}
    </BoxContents>
  </Box>
);
