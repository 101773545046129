import * as React from 'react';
import styled from 'styled-components';

import { DatePicker } from '@oysterjs/ui/Form/datepicker';

import {
  IoAirplane,
  IoBandage,
  IoBonfire,
  IoCheckmarkCircle,
  IoCheckmarkSharp,
  IoCloseSharp,
  IoCog,
  IoEllipseOutline,
  IoFlame,
  IoFlash,
  IoHammer,
  IoHome,
  IoLayers,
  IoLocation,
  IoMedkit,
  IoRainy,
  IoSad,
  IoShield,
  IoWater
} from 'react-icons/io5';

import { createClaimAttachments, deleteClaimAttachment } from '@oysterjs/core/api/claims';
import { CardGallery, SelectableOptionCard } from '@oysterjs/ui/Card';
import { TextAreaInput, TextInput } from '@oysterjs/ui/Form/text';
import { Spinner } from '@oysterjs/ui/Spinner';
import { RequirementItem } from '@oysterjs/ui/common';
import { getProductDisplayName } from '@oysterjs/core/policy';
import {
  Claim,
  ClaimAttachment,
  ClaimSection,
  ClaimSectionItem,
  ClaimType,
  ConfirmItem,
  LocationItem,
  Product,
  SelectItem,
  TextItem,
  UploadItem,
  ValidationError
} from '@oysterjs/types';

import { Attachment } from '../attachment';
import { ClaimSectionItemFunction } from './section';
import { AddressInput } from '@oysterjs/ui/Form/address';
import { Switch } from '@oysterjs/ui/Form/switch';
import { useId } from 'react-id-generator';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';

export const ErrorDisplay = styled.div`
  font-size: 0.8em;
  margin-top: 5px;
  color: #d1344b;
`;

export const ProductSection = (props: {
  products: Product[];
  loading?: boolean;
  selectedProductId?: string;
  validationError?: ValidationError;
  onContinue: (product: Product) => void;
}) => {
  const [selectedProductId, setSelectedProductId] = React.useState(props.selectedProductId);

  React.useEffect(() => {
    setSelectedProductId(props.selectedProductId);
  }, [props.selectedProductId]);

  if (props.products.length === 0) {
    return <p style={{ color: '#999999' }}>You don't have any items you can file a claim for.</p>;
  }

  return (
    <CardGallery>
      {props.products.map((product) => (
        <SelectableOptionCard
          key={product.ID}
          title={product.Name}
          description={getProductDisplayName(product.Type)}
          selected={selectedProductId !== undefined && product.ID === selectedProductId}
          disabled={props.loading}
          loading={props.loading && product.ID === selectedProductId}
          onClick={() => {
            setSelectedProductId(product.ID);
            props.onContinue(product);
          }}
          image={<img src={product.ImageURL} />}
        />
      ))}
      {props.validationError && <ErrorDisplay>{props.validationError.Message}</ErrorDisplay>}
    </CardGallery>
  );
};

export const getFieldKey = (...fields: string[]): string => fields.join('.').replace(/\.+$/g, '');

export const getData = <T,>(claim: Claim, ...fields: string[]): T => {
  const key = getFieldKey(...fields);
  if (claim[key]) {
    return claim[key];
  }

  // eslint-disable-next-line
  // @ts-ignore
  return claim.Data[key] as T;
};

const getClaimIcon = (claimType: string) =>
  ({
    // claim type icons
    [ClaimType.theft]: <IoShield aria-hidden />,
    [ClaimType.damage]: <IoBandage aria-hidden />,
    [ClaimType.injury]: <IoMedkit aria-hidden />,
    [ClaimType.liability]: <IoHammer aria-hidden />,
    [ClaimType.other]: <IoSad aria-hidden />,
    [ClaimType.electricalBreakdown]: <IoFlash aria-hidden />,
    [ClaimType.mechanicalBreakdown]: <IoCog aria-hidden />,
    [ClaimType.naturalDisaster]: <IoBonfire aria-hidden />,
    [ClaimType.waterDamage]: <IoWater aria-hidden />,
    // damage type icons
    CrackedScreen: <IoLayers aria-hidden />,
    SpillOrLiquid: <IoWater aria-hidden />,
    SomethingElse: <IoSad aria-hidden />,
    Fire: <IoFlame aria-hidden />,
    Flood: <IoWater aria-hidden />,
    Lightning: <IoFlash aria-hidden />,
    Wind: <IoRainy aria-hidden />,

    // theft type icons
    TheftFromHome: <IoHome aria-hidden />,
    TheftAwayFromHome: <IoLocation aria-hidden />,
    LostInTransport: <IoAirplane aria-hidden />,

    // boolean icons
    Yes: <IoCheckmarkSharp aria-hidden />,
    No: <IoCloseSharp aria-hidden />
  })[claimType] || <></>;

export const SelectSection: ClaimSectionItemFunction = {
  autoContinue: true,
  getData: (claim) => [claim.Types.length > 0],
  component: (props) => {
    const sectionItem = props.sectionItem.Item as SelectItem;
    const [selected, setSelected] = React.useState(
      getData<string[]>(props.claim, props.section.Field, props.sectionItem.SubField) || []
    );

    React.useEffect(() => {
      setSelected(getData(props.claim, props.section.Field, props.sectionItem.SubField) || []);
    }, [props.claim, props.section, props.sectionItem]);

    return (
      <CardGallery>
        {sectionItem.AllowedValues.map((type) => (
          <SelectableOptionCard
            key={type.Value}
            title={type.Title}
            description={type.Description}
            selected={selected.includes(type.Value)}
            disabled={props.loading}
            loading={props.loading && selected.includes(type.Value)}
            onClick={() => {
              setSelected([type.Value]);
              props.setData(
                getFieldKey(props.section.Field, props.sectionItem.SubField),
                type.Value
              );
            }}
            image={getClaimIcon(type.Icon)}
          />
        ))}
      </CardGallery>
    );
  }
};

export const CheckboxSection: ClaimSectionItemFunction = {
  getData: () => [],
  component: (props) => {
    const [selected, setSelected] = React.useState(
      getData<string>(props.claim, props.section.Field, props.sectionItem.SubField) || ''
    );

    React.useEffect(() => {
      setSelected(getData(props.claim, props.section.Field, props.sectionItem.SubField) || '');
    }, [props.claim, props.section, props.sectionItem]);

    return (
      <Switch
        enabled={!!selected}
        onChange={() => {
          setSelected(selected ? '' : '1');
          props.setData(
            getFieldKey(props.section.Field, props.sectionItem.SubField),
            selected ? '' : '1'
          );
        }}
      />
    );
  }
};

export const TextSection: ClaimSectionItemFunction = {
  getData: (claim, section, sectionItem) =>
    (sectionItem.Item as TextItem).Required
      ? [getData(claim, section.Field, sectionItem.SubField)]
      : [],
  onContinue: (claim, section, sectionItem, setData) => {
    if (
      !(sectionItem.Item as TextItem).Required &&
      !getData(claim, section.Field, sectionItem.SubField)
    ) {
      setData(getFieldKey(section.Field, sectionItem.SubField), '');
    }
  },
  component: (props) => {
    const [id] = useId();
    const [text, setText] = React.useState(
      getData<string>(props.claim, props.section.Field, props.sectionItem.SubField) || ''
    );
    const [validationError, setValidationError] = React.useState(props.validationError);

    React.useEffect(() => {
      setValidationError(props.validationError);
    }, [props.validationError]);

    React.useEffect(() => {
      setText(getData(props.claim, props.section.Field, props.sectionItem.SubField) || '');
    }, [props.claim, props.section, props.sectionItem]);

    return (
      <div style={{ maxWidth: '600px' }}>
        <label htmlFor={id} style={{ display: 'none' }}>
          {props.section.Title}
        </label>
        <TextInput
          id={id}
          value={text}
          error={validationError?.Message}
          onChange={(e) => {
            setValidationError(undefined);
            setText(e.currentTarget.value);
            props.setData(
              getFieldKey(props.section.Field, props.sectionItem.SubField),
              e.currentTarget.value
            );
          }}
          disabled={props.loading}
        />
      </div>
    );
  }
};

export const DateSection: ClaimSectionItemFunction = {
  getData: (claim, section, sectionItem) => [
    new Date(getData(claim, section.Field, sectionItem.SubField) || '').valueOf() > 0
  ],
  component: (props) => {
    const [date, setDate] = React.useState(
      new Date(getData(props.claim, props.section.Field, props.sectionItem.SubField) || '')
    );
    const [validationError, setValidationError] = React.useState(props.validationError);

    React.useEffect(() => {
      setValidationError(props.validationError);
    }, [props.validationError]);

    React.useEffect(() => {
      setDate(
        new Date(getData(props.claim, props.section.Field, props.sectionItem.SubField) || '')
      );
    }, [props.claim, props.section, props.sectionItem]);

    return (
      <>
        <DatePicker
          loading={props.loading}
          onDayClick={(date) => {
            setValidationError(undefined);
            props.setData(
              getFieldKey(props.section.Field, props.sectionItem.SubField),
              date.toISOString()
            );
          }}
          selected={date}
          disabled={(day) => {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            return day.getTime() >= today.setDate(today.getDate() + 1);
          }}
        />
        {validationError && <ErrorDisplay>{validationError?.Message}</ErrorDisplay>}
      </>
    );
  }
};

const getLocationSectionState = (
  claim: Claim,
  section: ClaimSection,
  sectionItem: ClaimSectionItem
): [string, number, number] => [
  getData(
    claim,
    section.Field,
    sectionItem.SubField,
    (sectionItem.Item as LocationItem).AddressField
  ),
  parseFloat(
    getData(
      claim,
      section.Field,
      sectionItem.SubField,
      (sectionItem.Item as LocationItem).LongitudeField
    ) || '0'
  ),
  parseFloat(
    getData(
      claim,
      section.Field,
      sectionItem.SubField,
      (sectionItem.Item as LocationItem).LatitudeField
    ) || '0'
  )
];

export const LocationSection: ClaimSectionItemFunction = {
  getData: getLocationSectionState,
  component: (props) => {
    const [id] = useId();

    const sectionItem = props.sectionItem.Item as LocationItem;
    const [state, setState] = React.useState<[string, number, number]>(
      getLocationSectionState(props.claim, props.section, props.sectionItem)
    );

    React.useEffect(() => {
      setState(getLocationSectionState(props.claim, props.section, props.sectionItem));
    }, [props.claim, props.section, props.sectionItem]);

    const setData = (address: string, longitude: number, latitude: number) => {
      setState([address, longitude, latitude]);
      props.setData(
        getFieldKey(props.section.Field, props.sectionItem.SubField, sectionItem.AddressField),
        address
      );
      props.setData(
        getFieldKey(props.section.Field, props.sectionItem.SubField, sectionItem.LongitudeField),
        longitude.toString()
      );
      props.setData(
        getFieldKey(props.section.Field, props.sectionItem.SubField, sectionItem.LatitudeField),
        latitude.toString()
      );
    };

    return (
      <>
        <label htmlFor={id} style={{ display: 'none' }}>
          {props.section.Title}
        </label>
        <AddressInput
          id={id}
          validationError={props.validationError}
          onChange={setData}
          initialAddress={state}
          disabled={props.loading}
        />
      </>
    );
  }
};

export const LongTextSection: ClaimSectionItemFunction = {
  getData: (claim, section, sectionItem) => [getData(claim, section.Field, sectionItem.SubField)],
  component: (props) => {
    const [id] = useId();
    const [text, setText] = React.useState(
      getData<string>(props.claim, props.section.Field, props.sectionItem.SubField)
    );
    const [validationError, setValidationError] = React.useState(props.validationError);

    React.useEffect(() => {
      setValidationError(props.validationError);
    }, [props.validationError]);

    React.useEffect(() => {
      setText(getData(props.claim, props.section.Field, props.sectionItem.SubField));
    }, [props.claim, props.section, props.sectionItem]);

    return (
      <div style={{ maxWidth: '600px' }}>
        <label htmlFor={id} style={{ display: 'none' }}>
          {props.section.Title}
        </label>
        <TextAreaInput
          rows={8}
          id={id}
          style={{ resize: 'none' }}
          value={text}
          error={validationError?.Message}
          onChange={(e) => {
            setValidationError(undefined);
            setText(e.currentTarget.value);
            props.setData(
              getFieldKey(props.section.Field, props.sectionItem.SubField),
              e.currentTarget.value
            );
          }}
          disabled={props.loading}
        />
      </div>
    );
  }
};

const UploadGroupContainer = styled.div<{ enabled?: boolean; error?: boolean; dragging?: boolean }>`
  box-shadow: ${(props) =>
    props.error ? '0 0 0 2px #d1344b' : props.enabled ? '0 0 0 2px #0ea5e9' : '0 0 0 0px #C8C8C8'};
  border: ${(props) =>
    props.enabled || props.error ? '1px solid transparent' : '1px dashed #C8C8C8'};
  border-radius: 8px;
  transition: 0.15s all ease-in-out;
  padding: 16px;
  box-sizing: border-box;
  background: ${(props) => (props.dragging ? '#e2e2e2' : 'white')};

  display: flex;
  flex-direction: row;
  margin: 0px;

  * {
    pointer-events: none;
  }
`;

const UploadGroupCheckboxContainer = styled.div`
  width: 30px;
`;

const UploadGroupContentContainer = styled.div`
  width: calc(100% - 30px);
`;

const UploadGroupDescription = styled.div`
  color: #999999;
  font-size: 0.9em;
  margin-top: 5px;
`;

const UploadGroupContent = styled.div`
  font-size: 0.9em;
  margin-top: 5px;
  padding: 10px 0px 5px 0px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;

  * {
    pointer-events: auto;
  }
`;

const UploadGroupHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
`;

const getFilesFromEvent = (dataTransfer?: DataTransfer): File[] => {
  const files: File[] = [];

  if (dataTransfer && dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (dataTransfer.items[i].kind === 'file') {
        const file = dataTransfer.items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
  } else if (dataTransfer && dataTransfer.files) {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < dataTransfer.files.length || 0; i++) {
      const file = dataTransfer.files[i];
      if (file) {
        files.push(file);
      }
    }
  }

  return files;
};

export const UploadSection: ClaimSectionItemFunction = {
  getData: () => [],
  component: (props) => {
    const sectionItem = props.sectionItem.Item as UploadItem;
    const [loading, setLoading] = React.useState(false);
    const [dragging, setDragging] = React.useState(false);

    const attachRef = React.useRef<HTMLInputElement>(null);
    const [claimAttachments, setClaimAttachments] = React.useState<ClaimAttachment[]>(
      props.claim.Attachments[getFieldKey(props.section.Field, props.sectionItem.SubField)] || []
    );
    const [fileList, setFileList] = React.useState<File[]>([]);

    const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);

    React.useEffect(() => {
      setValidationErrors(
        props.validationError?.SubField === props.sectionItem.SubField
          ? [props.validationError]
          : []
      );
    }, [props.validationError]);

    const completed = !loading && fileList.length === 0 && claimAttachments.length > 0;

    const uploadFiles = (files: File[]) => {
      if (files.length === 0) {
        setLoading(false);
        return Promise.resolve();
      }

      return createClaimAttachments(props.claim.ID, props.sectionItem.SubField, [files[0]])
        .catch((e) => {
          const err = WrappedError.asWrappedError(e);
          if (err.type() === ErrorType.validationError) {
            setValidationErrors((prev) => [...prev, err.getValidationError()]);
          } else {
            setValidationErrors((prev) => [
              ...prev,
              { Field: '', SubField: '', Message: err.message }
            ]);
          }
        })
        .then(() =>
          props
            .updateClaim()
            .then((claim) =>
              setClaimAttachments(
                claim.Attachments[getFieldKey(props.section.Field, props.sectionItem.SubField)] ||
                  []
              )
            )
        )
        .then(() =>
          setFileList((prev) => {
            const newList = prev.filter((f) => f.name !== files[0].name);
            return newList;
          })
        )
        .then(() => uploadFiles(files.slice(1)));
    };

    const addFiles = (files: File[]) => {
      setValidationErrors([]);
      setFileList((prev) => [...prev, ...files]);
      setLoading(true);
      uploadFiles(files);
    };

    const onDragEnter = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
      e.preventDefault();
      setDragging(true);
    };

    const onDragLeave = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
      e.preventDefault();
      setDragging(false);
    };

    const onDragOver = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
      e.preventDefault();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'copy';
      }
    };

    const onDrop = (e: { dataTransfer?: DataTransfer; preventDefault: () => void }) => {
      e.preventDefault();
      setDragging(false);

      const files = getFilesFromEvent(e.dataTransfer);
      if (files.length > 0) {
        addFiles(files);
      }
    };

    return (
      <form style={{ display: 'block', maxWidth: '600px' }}>
        <input
          type="file"
          name="files"
          multiple
          ref={attachRef}
          style={{ display: 'none' }}
          onChange={(e) => addFiles(Array.from(e.currentTarget?.files || []))}
        />
        <UploadGroupContainer
          enabled={completed}
          dragging={dragging}
          error={!completed && validationErrors.length > 0}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onClick={() => attachRef?.current?.click()}
        >
          <UploadGroupCheckboxContainer>
            {!loading && !completed && (
              <IoEllipseOutline style={{ color: '#cccccc', fontSize: '1.3em' }} />
            )}
            {!loading && completed && (
              <IoCheckmarkCircle style={{ color: '#0EA5E9', fontSize: '1.3em' }} />
            )}
            {loading && <Spinner size={16} color="#666666" />}
          </UploadGroupCheckboxContainer>
          <UploadGroupContentContainer>
            <UploadGroupHeaderContainer>{sectionItem.Name}</UploadGroupHeaderContainer>
            <UploadGroupDescription>{sectionItem.Description}</UploadGroupDescription>
            {fileList.length + claimAttachments.length > 0 && (
              <UploadGroupContent>
                {claimAttachments.map((f) => (
                  <Attachment
                    key={f.Name}
                    name={f.Name}
                    size={f.Size}
                    onRemove={() => {
                      setLoading(true);
                      deleteClaimAttachment(props.claim.ID, f.ID)
                        .then(() =>
                          props
                            .updateClaim()
                            .then((claim) =>
                              setClaimAttachments(
                                claim.Attachments[
                                  getFieldKey(props.section.Field, props.sectionItem.SubField)
                                ] || []
                              )
                            )
                        )
                        .then(() => setLoading(false));
                    }}
                  />
                ))}
                {fileList.map((f) => (
                  <Attachment uploading key={'uploading-' + f.name} name={f.name} size={f.size} />
                ))}
              </UploadGroupContent>
            )}
            {validationErrors.map((err) => (
              <ErrorDisplay key={err.Message}>{err.Message}</ErrorDisplay>
            ))}
          </UploadGroupContentContainer>
        </UploadGroupContainer>
      </form>
    );
  }
};

export const ConfirmSection: ClaimSectionItemFunction = {
  submit: true,
  getData: () => [],
  component: (props) => {
    const sectionItem = props.sectionItem.Item as ConfirmItem;
    const [validationError, setValidationError] = React.useState(props.validationError);

    React.useEffect(() => {
      setValidationError(props.validationError);
    }, [props.validationError]);

    return (
      <>
        {sectionItem.Confirmation.map((item) => (
          <RequirementItem key={item.Title} name={item.Title} description={item.Description} />
        ))}
        {validationError && <ErrorDisplay>{validationError?.Message}</ErrorDisplay>}
      </>
    );
  }
};
