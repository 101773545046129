import { init as initApm } from '@oysterjs/core/apm';
initApm();

import { init as initAppConfiguration } from '@oysterjs/core/api/appConfiguration';
initAppConfiguration();

import './style.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route as BrowserRoute,
  useRouteMatch,
  useHistory
} from 'react-router-dom';

import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import config from '@oysterjs/core/config';

import PolicyholderDashboardPage from './pages/PolicyholderDashboard';
import { SignIn, SignUp } from './pages/SignIn';
import { useAuth } from '@oysterjs/core/auth';
import { useGA } from '@oysterjs/core/ga';
import { useGoogleTags } from '@oysterjs/core/analytics/googletags';
import { HubspotChatContextProvider } from '@oysterjs/core/hubspot';

const Authenticated = (props: React.PropsWithChildren<unknown>) => {
  const [auth] = useAuth();
  const history = useHistory();
  const match = useRouteMatch();

  React.useEffect(() => {
    if (!auth.Token && match.path !== '/signin') {
      history.push('/signin');
    }
  }, [auth.Token]);

  if (!auth.Token && match.path !== '/signin') {
    return null;
  }

  return <>{props.children}</>;
};

const App = () => {
  if (config().environment === 'production') {
    useGA('G-MX1C87Q096');
    useGoogleTags();
  }

  return (
    <HubspotChatContextProvider>
      <Switch>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/">
          <Authenticated>
            <PolicyholderDashboardPage />
          </Authenticated>
        </Route>
        <Route>
          <h1>404</h1>
        </Route>
      </Switch>
    </HubspotChatContextProvider>
  );
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
